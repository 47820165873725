// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import styled from 'styled-components';
import AuthService from '../common/AuthService';
import { PRODUCTS_URL } from '../common/urls';
import { getRequest } from '../util/request';
import CommonButton from '../common/CommonButton';
import { useLocation, useSearchParams } from 'react-router-dom';

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  .inventory-page{
    max-height: calc(100vh - 130px);
  }
`

const ProductInventoryWrapper = styled.div`
  // max-height: calc(100vh - 200px);
  // width: 95%;
  box-shadow: 1px 1px 4px 1px #c7c7c7;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  // width: 90%;
  margin-bottom: 10px;
  .table-wrapper{
    max-height: calc(100vh - 180px);
    overflow: auto;
    .products-tabls{
      border-spacing: 0;
      border-collapse: collapse;
      padding: 10px;
      // border: 0.5px solid #49654c;
      .header-row{
        // border-bottom: 2px solid black;
      }
      tr{
        text-align: left;
        // border-bottom: 0.5px solid black;
  
        &.header-row{
          th{
            position: sticky;
            top:0px;
            padding: 10px 25px;
            background-color: #e6e6e6;
          }
        }
  
        &.product-row:nth-child(even){
          background-color: #f9fff9;
        }
        &.product-row:nth-child(odd){
          background-color: white;
        }
        &.product-row{
          td.product-detail{
            margin-left: 10px;
            padding: 10px 25px;
            vertical-align: center;
            
            &.product-detail-label{
              min-width: 400px;
            }
            &.product-detail-condition{
              min-width: 100px;
            }
            &.product-detail-image{
              .product-img{
                width: 90px;
                border: 0.5px solid #b2dcb9;
                padding: 5px;
                background-color: white;
              }
            } 
    
            &.product-detail-status{
              min-width: 100px;
              .product-detail-value{
                text-align: center;
                padding: 5px;
                border-radius: 5px;
                font-size: 14px;
                width: 70px;
                background-color: #c7c7c7;
                color: rgb(95, 94, 89);
                border: 1px solid  rgb(82, 81, 76);
    
                &.status-active{
                  background-color: #CCD9CE; 
                  color: #56805B;
                  border: 1px solid #56805B;
                  // border: 0;
                }
    
                &.status-sold-out{
                  background-color: #FEF3F2;
                  color: #F04438;
                  border: 1px solid #F04438;
                  // border: 0;
                }
              }
            }
  
            &.product-detail-condition{
              .condition-quality{
                width: 50px;
                height: 10px;
                border: 1px solid black;
                border-radius: 5px;
                background-color: #d1d1d1;
  
                &.sehr-gut{
                  background-color: #00e200;
                }
  
                &.refurbished{
                  background-color: orange;
                }
              }
            }
          }
        }
      }
    }
  }
`

const PaginationBar = styled.div`
  user-select: none;
  position: relative;
  // margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
  .page-title{
    font-weight: bold;
  }
  .navigation-button{
    font-weight: bold;
    cursor: pointer;
    &.disabled{
      color: darkgrey;
    }
  }
  .set-page-number-popover{
    position: absolute;
    top: -20px;
    left: 45%;
    &.hidden{
      display: none;
    }
  }
  .available-pages{
    .page-selector-input{
      padding: 5px 10px;
      input{
        border-radius: 5px;
        border: 0.5px solid grey;
        padding: 5px;
        width: 20px;
        text-align: center;
      }
    }
  }
`

const LinkItem = styled.button`
  user-select: none;
  display: inline-block;
  cursor: pointer;
  color: #3e5d43;
  border-radius: 5px;
  border: 0;
  font-size: 14px;
  height: 35px;
  width: 35px;
  margin: 0 5px;
  &:hover{
    background-color: #dedede;
  }
  &.selected{
    font-weight: bold;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
`;

const getStatusObject = (product) => {
  var stock = product.inventory_quantity;
  var statusLabel = product.parent.status; 
  var className = "status-active"
  // if(product.variants){
  //   product.variants.forEach((variant) => {stock += variant.inventory_quantity});
  // }
  var productStatus = product.parent.status;
  if (productStatus == 'ACTIVE') {
    if(!stock){
      statusLabel = "Sold Out"
      className = "status-sold-out";
    } else {
      statusLabel = 'Online'
    }
    return {
      label: statusLabel.charAt(0).toUpperCase() + statusLabel.slice(1),
      className: className,
      stock: stock
    };
  } else {
    className = "status-unknown";
  }
  return {
    label: statusLabel.charAt(0).toUpperCase() + statusLabel.slice(1),
    className: className,
    stock: stock
  };
}

const getStock = (product) => {
  var stock = 0;
  if (product.status == 'active' && (product.variants)) {
    product.variants.forEach((variant) => {stock += variant.inventory_quantity});
  }
  return stock;
}

const getConditionHTML = (zustandText) => {
  if(zustandText == 'sehr gut'){

    return (<div className="condition-quality sehr-gut"></div>)
  } else if(zustandText.includes("refurbished")){

    return (<div className="condition-quality refurbished"></div>)
  } else {

    return (<div className="condition-quality"></div>)
  }
}

const PAGE_SIZE = 25;
const PAGE_QUERY_KEY = 'page';
const PAGE_LIMIT_QUERY_KEY = 'limit';

const Inventory = (props) => {
  const [user, setUser] = useState({});
  // const [page, setPage] = useState(1);
  const [variants, setVariants] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectorValue, setSelectorValue] = useState(1);
  const [shouldShowPageNumberSelector, setShowPageNumberSelector] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(PAGE_QUERY_KEY) ? Number(searchParams.get(PAGE_QUERY_KEY)) : 1;
  const pageSize = searchParams.get(PAGE_LIMIT_QUERY_KEY) ? searchParams.get(PAGE_LIMIT_QUERY_KEY) : PAGE_SIZE;
  const adminViewAs = searchParams.get('viewAs');
  console.log(searchParams);
  console.log(adminViewAs);
  const products = variants;
  const shouldShowAdminColumns = user['is_superuser'] && !adminViewAs;
  useEffect(()=>{
    AuthService.getUserInfo((user) => {setUser(user)});
  },[])
  useEffect(()=>{
    navigatePage(1);
  },[adminViewAs])
  useEffect(() => {
    // console.log()
    if(AuthService.isLoggedIn()){
      getRequest(`${PRODUCTS_URL}?page=${page}&size=${pageSize}${adminViewAs ? `&customerId=${adminViewAs}` : ""}`, (response)=> {
        // console.log(response.variants);
        setVariants(response.variants);
        setTotalPages(response.total_pages);
      })
    }
    setSelectorValue(page)
  }, [page, props])
  const navigatePage = (page) => {
    var newPage = Number(page);
    if(newPage > totalPages && totalPages){
      newPage = totalPages;
    } else if(newPage < 1){
      newPage = 1;
    }
    searchParams.set(PAGE_QUERY_KEY, newPage);
    searchParams.set(PAGE_LIMIT_QUERY_KEY, pageSize);
    setSearchParams(searchParams);
  }
  var pagesSpan = []; 
  const getPages = (startIndex, endIndex, selectedPage) => {
    var pageItems = []
    for(var pageIndex = startIndex ; pageIndex <= endIndex ; pageIndex++){
      pageItems.push(
        <LinkItem 
          className={`page-link ${pageIndex == selectedPage ? "selected" : ""}`}
          onClick={(e) => {navigatePage(Number(e.target.innerText))}}
        >
            {pageIndex}
        </LinkItem>
      );
    }
    return pageItems;
  }
  if(totalPages < 10){
    pagesSpan = getPages(1, totalPages, page);
  } else {
    pagesSpan.push(...getPages(1, 2, page));
    pagesSpan.push(<span className="page-selector-input">
      <input value={selectorValue} 
        onChange={(e) => {setSelectorValue(e.target.value)}}
        onKeyDown={(e) => {if (e.key === 'Enter') { if(selectorValue){e.target.blur();navigatePage(Number(selectorValue)) }}}}
      />
    </span>);
    pagesSpan.push(...getPages(totalPages - 1, totalPages, page));
  }
  return (
    <PageWrapper>
      <h2>Inventory</h2>
      <div className="inventory-page">
        <ProductInventoryWrapper>
          <div className="table-wrapper">
            <table className="products-tabls">
              <tr className="header-row">
                <th></th>
                <th>Product Name</th>
                <th>Resale Price</th>
                <th>RRP</th>
                <th>Condition</th>
                <th>Brand</th>
                <th>Sale Status</th>
                <th>Stock</th>
                <th>Tildi SKU</th>
                {
                  shouldShowAdminColumns ? 
                  (
                    <>
                      <th>Vendor</th>
                      <th>Fulfillment Center</th>
                    </>
                  ) 
                  : ""
                }
              </tr>
              {products.map((product, index) => {
                var productOptions = product.options;
                const conditionText = product.condition;
                const statusObj = getStatusObject(product);
                return (
                  <tr className="product-row" key={index}>
                    <td className="product-detail product-detail-image">
                      <img className="product-img" src={product.featured_image ? product.featured_image : product.parent.featured_image}/>
                    </td>
                    <td className="product-detail product-detail-label" title={`Name: ${product['display_name']}`}>
                      {product['display_name']}
                    </td>
                    <td className="product-detail product-detail-price" title={`Price: ${product['price']}`}>
                      {product['price'] ? Number(product['price']).toLocaleString('de-DE') : ""}€
                    </td>
                    <td className="product-detail product-detail-price" title={`RRP: ${product['compare_at_price']}`}>
                      {product['compare_price'] ? Number(product['compare_price']).toLocaleString('de-DE') : 0}€
                    </td>
                    <td className="product-detail product-detail-condition">
                      {conditionText}
                    </td>
                    <td className="product-detail product-detail-sku" title={`Brand: ${product.parent.brand}`}>
                      {product.parent.brand}
                    </td>
                    <td className="product-detail product-detail-status" title={`Status: ${statusObj.label}`}>
                      <div className={`product-detail-value ${statusObj.className}`}>{statusObj.label}</div>
                    </td>
                    <td className="product-detail product-detail-stock" title={`Stock: ${product.inventory_quantity}`}>
                      {product.inventory_quantity ? product.inventory_quantity : 0}
                    </td>
                    <td className="product-detail product-detail-sku" title={`Tildi SKU: ${product['sku']}`}>
                      {product['sku']}
                    </td>
                    {
                      shouldShowAdminColumns ? 
                      (
                        <>
                          <td className="product-detail product-detail-sku">
                            {product.parent.seller_name}
                          </td>
                          <td className="product-detail product-detail-sku">
                            {product.warehouse}
                          </td>
                        </>
                      ) 
                      : ""
                    }
                  </tr>
                )
            })}
            </table>
          </div>
        </ProductInventoryWrapper>
      </div>
      <PaginationBar>
        <div className={`set-page-number-popover ${selectorValue && page != selectorValue ? '' : 'hidden'}`}>
          <CommonButton onClick={()=>{navigatePage(Number(selectorValue))}}>Navigate</CommonButton> 
        </div>
        <div className={`navigation-button prev-button ${page == 1 ? 'disabled' : ''}`} onClick={()=>{navigatePage(page - 1)}}>Back</div>
        <div className="available-pages">{pagesSpan}</div>
        <div className={`navigation-button next-button ${page == totalPages ? 'disabled' : ''}`} onClick={()=>{navigatePage(page + 1)}}>Next</div>
      </PaginationBar>
    </PageWrapper>
  );
};

export default Inventory;
